var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('td',{staticClass:"px-4",style:(_vm.canViewOriginalPrice
        ? 'width: 20%'
        : 'width: 30%')},[_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v(_vm._s(_vm.productItem.barCode))]),_c('br'),_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v("("+_vm._s(_vm.productItem.productCode)+")")]),_c('br'),_c('span',[_vm._v(_vm._s(_vm.productItem.name))])]),_c('td',{staticClass:"px-4 text-right",style:(_vm.canViewOriginalPrice
        ? 'width: 5%'
        : 'width: 10%')},[_c('span',[_vm._v(_vm._s(_vm.productItem.totalQuantityInStock))])]),_c('td',{staticClass:"px-4",style:(_vm.canViewOriginalPrice
        ? 'width: 19%'
        : 'width: 25%')},[(_vm.editState)?_c('div',[_c('b-form-textarea',{staticClass:"w-100 h-90",staticStyle:{"border-color":"#007bff"},attrs:{"disabled":_vm.productItem.productType !== _vm.productType.PRODUCT_IMEI,"size":"sm","placeholder":'IMEI'},on:{"keyup":function($event){return _vm.enterClicked()},"change":_vm.checkValidation},model:{value:(_vm.productItem.IMEI),callback:function ($$v) {_vm.$set(_vm.productItem, "IMEI", $$v)},expression:"productItem.IMEI"}})],1):_c('span',[_vm._v(_vm._s(_vm.productItem.IMEI))])]),_c('td',{staticClass:"px-4 text-right",style:(_vm.canViewOriginalPrice
        ? 'width: 14%;'
        : 'width: 25%;')},[(_vm.editState)?_c('div',[_c('b-form-input',{staticClass:"w-100 h-90 text-right",staticStyle:{"height":"calc(1.35rem + 1.1rem + 2px)","border-color":"#007bff"},attrs:{"disabled":_vm.productItem.productType === _vm.productType.PRODUCT_IMEI,"type":"number","min":0,"placeholder":"Số lượng"},on:{"change":_vm.checkValidation},model:{value:(_vm.productItem.quantity),callback:function ($$v) {_vm.$set(_vm.productItem, "quantity", $$v)},expression:"productItem.quantity"}})],1):_c('span',[_vm._v(_vm._s(_vm.productItem.quantity))])]),_c('td',{directives:[{name:"show",rawName:"v-show",value:(_vm.canViewOriginalPrice),expression:"canViewOriginalPrice"}],staticClass:"px-4 text-right",staticStyle:{"width":"20%"}},[(_vm.editState)?_c('div',[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.mask),expression:"mask"}],staticClass:"w-100 h-90 text-right",staticStyle:{"height":"calc(1.35rem + 1.1rem + 2px)","border-color":"#007bff"},attrs:{"size":"sm","placeholder":"Đơn giá","min":1},on:{"change":_vm.checkValidation},model:{value:(_vm.productItem.price),callback:function ($$v) {_vm.$set(_vm.productItem, "price", $$v)},expression:"productItem.price"}}),(_vm.error)?_c('p',{staticStyle:{"color":"red"}},[_vm._v("Hãy nhập đầy đủ thông tin")]):_vm._e()],1):_c('span',[_vm._v(_vm._s(_vm.formatMoney(_vm.productItem.price)))])]),_c('td',{directives:[{name:"show",rawName:"v-show",value:(_vm.canViewOriginalPrice),expression:"canViewOriginalPrice"}],staticClass:"px-4 text-right",staticStyle:{"width":"15%"}},[_c('span',[_vm._v(_vm._s(_vm.formatMoney(_vm.totalPriceOfProduct)))])]),(_vm.editState)?_c('td',{staticStyle:{"width":"10%"}},[[_c('div',{staticClass:"d-flex justify-content-center"},[_c('v-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"text-danger",attrs:{"small":"","title":"Xóa"},on:{"click":_vm.showDeleteAlert}},[_vm._v("mdi-delete")])],1)]],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }